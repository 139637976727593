import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card } from "reactstrap";
import _ from "lodash"
import {
  MdOutlineSentimentDissatisfied,
  MdOutlineSentimentNeutral,
  MdOutlineSentimentSatisfied,
  MdOutlineSentimentVeryDissatisfied,
  MdOutlineSentimentVerySatisfied
} from 'react-icons/md'

const customWidth = 25

const QuestionsTableCard = ({ title, questions, setQuestionToUpdate, customActions, buttons, isLoading }) => {

  const columns = [
    {
      name: "Pregunta",
      property: 'question',
      isTh: true
    },
    {
      name: "Respuestas",
      formattedContent: ({ answers }) => answers.length
    },
    {
      name: "Media",
      formattedContent: ({ answers }) => {
        if(!answers.length) return 0
        const addition = answers.reduce((r, {score}) => r+score, 0)
        return (addition/answers.length).toFixed(1)
      }
    },
    {
      name: "Respuestas",
      formattedContent: ({ answers }) => {
        const [one, two, three, four, five] = answers.reduce((r, {score}) => {
          r[score] = r[score]+1
          return r
        }, [0,0,0,0,0])
        return <>
          <span className="ml-2">{five}<MdOutlineSentimentVerySatisfied color="#51a2fe" size={customWidth} /></span>
          <span className="ml-2">{four}<MdOutlineSentimentSatisfied color="#76d5a9" size={customWidth} /></span>
          <span className="ml-2">{three}<MdOutlineSentimentNeutral color="#85f450" size={customWidth} /></span>
          <span className="ml-2">{two}<MdOutlineSentimentDissatisfied color="#ffca45" size={customWidth} /></span>
          <span className="ml-2">{one}<MdOutlineSentimentVeryDissatisfied color="#f43229" size={customWidth} /></span>
        </>
      }
    },
    {
      name: "",
      formattedContent: (question, i) =>
        <TableOutput.CustomActions
          actions={[
            ...(customActions ? customActions(_.cloneDeep(question), i) : []),
            ...(!!setQuestionToUpdate ? [{
              icon: <i className="fas fa-edit"/>,
              onClick: () => setQuestionToUpdate(_.cloneDeep(question)),
              description: "Editar pregunta"
            }] : []),
          ]}
        />
    }
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={title}
        buttons={buttons}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={questions}
        onRowClick={question => setQuestionToUpdate(_.cloneDeep(question))}
        isLoading={isLoading}
        noContentMessage="No hay apreguntas"
        // rowHeight={81}
      />
    </Card>
  );
}

export default QuestionsTableCard;
import {useEffect, useState} from "react";
import { notify } from "@general-backoffice/core";
import { useSelector, useDispatch } from "react-redux";

import { setTown as setTownState } from "../../../redux/reducers/session/actions";
import { townRequests } from "../../../api/Api"

import handleError from "../../utils/handleError";
import getFileId from "../../utils/getFileId";
import {notifyLoadingToSuccess} from "../../utils/notifyLoadingUpdate";


const useTown = () => {
  const dispatch = useDispatch()
  const state = useSelector(({ sessionState }) => sessionState)
  const [town, setTown] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const update = () => {
    if (!town || isLoading) return;
    setLoading(true)
    return submitTown(town)
      .then(async () => {
        const towns = await townRequests.getList()
        dispatch(setTownState(towns.find(t => t.id === state.town.id) || towns[0], towns || []))
      })
      .finally(() =>
        setLoading(false)
      )
  }

  useEffect(() => {
    if(town) {
      const { manager, managerEmail } = town
      if(!managerEmail && manager)
        setTown(t => ({...t, managerEmail: manager.email}))
    }
    // eslint-disable-next-line
  }, [town?.manager])

  return {
    town,
    setTown,
    update,
    isLoading
  }
}


export const submitTown = async town => {
  const isNew = !town?.id

  const errMsg = `Error al ${isNew ? 'crear' : 'modificar'} la población`
  if (!town) throw new Error(errMsg)
  const notification = notify.loading(isNew ? "Creando población..." : "Modificando población...")
  try {
    const bannerId = await getFileId(town.banner)
    let res;
    if(isNew) res = await townRequests.create({...town, banner: bannerId })
    else res = await townRequests.update(town.id, {...town, banner: bannerId})

    notifyLoadingToSuccess(notification, `Población ${isNew ? 'creada' : 'modificada'} correctamente`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg, notification)
  }
}


export default useTown;
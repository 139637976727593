import React from "react";
import { Loading, NotificationWrapper, PersistAndProvider } from "@general-backoffice/core";
import sessionState from "./redux/reducers/session/state";
import {login, logout, setTown} from "./redux/reducers/session/actions";
import Router from "./Router";
import { sessionVersion as currentSessionVersion } from "./config/configuration";
import {townRequests} from "./api/Api";

const stores = {
  sessionState: {
    baseReducer: sessionState,
    persistConfig: {
      key: 'session',
      blacklist: []
    }
  },
}

const afterRehydrate = async (store) => {
  const state = store.getState();
  const { sessionState: { token, user, town, sessionVersion } } = state;
  if (user && token && sessionVersion === currentSessionVersion) {
    store.dispatch(login(token, user));
    if(user.role === 'Admin') {
      const towns = await townRequests.getList()
      store.dispatch(setTown(towns[0], towns));
    } else {
      const refreshedTown = await townRequests.get(town.id)
      if(refreshedTown) store.dispatch(setTown(refreshedTown, [refreshedTown]));
      else store.dispatch(logout())
    }
  } else store.dispatch(logout())
}

const App = () => {

  const loadingPage = (
    <div className="vh-100 flex-center">
      <Loading.BouncingDots size={"lg"}/>
    </div>
  )

  return (
    <PersistAndProvider stores={stores} afterRehydrate={afterRehydrate} loadingScreen={loadingPage}>
      <React.Suspense fallback={loadingPage}>
        <NotificationWrapper>
          <Router/>
        </NotificationWrapper>
      </React.Suspense>
    </PersistAndProvider>
  );
}

export default App;

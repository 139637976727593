import React from "react";
import { Navigate } from "react-router";
import Login from "../../views/pages/auth/Login";
// import ResetPassword from "../../views/pages/auth/ResetPassword";
// import ForgottenPassword from "../../views/pages/auth/ForgottenPassword";
import Auth from "../../views/layouts/Auth";

const subRoutes = () => [
  {
    path: "login",
    index: true,
    component: <Login/>
  },
  // {
  //   path: "forgotten-password",
  //   component: <ForgottenPassword/>
  // },
  // {
  //   path: "reset-password",
  //   component: <ResetPassword/>
  // },
  {
    path: "*",
    index: true,
    component: <Navigate to={"/auth/login"} replace={true}/>,
  }
]

const index = state => ({
  path: "/auth",
  component: <Auth/>,
  subRoutes: subRoutes(state)
})

export default index;
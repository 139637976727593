import React, {useMemo, useState} from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import _ from "lodash";

import useDefaultSurvey from "../../../../components/questions/hooks/useDefaultSurvey";
import useQuestions from "../../../../components/questions/hooks/useQuestions";
import useQuestion from "../../../../components/questions/hooks/useQuestion";


// Components
import QuestionsTableCard from "../../../../components/questions/QuestionsTableCard";
import UpdateQuestionModal from "../../../../components/questions/UpdateQuestionModal";
import AddButtonChildren from "../../../../components/shared/AddButtonChildren";


const DefaultSurvey = () => {
  const {
    defaultSurvey,
    setDefaultSurvey,
    update: updateSurvey,
    refresh: refreshSurvey,
    isLoading: isLoadingSurvey
  } = useDefaultSurvey()
  const [orderHasChanged, setOrderHasChanged] = useState(false)

  console.log('defaultSurvey')

  const unusedQuestionsParams = useMemo(() => ({
    populate: ['answers', 'localizations'],
    'filters[town][id][$null]': true,
    ...(defaultSurvey && {'filters[id][$notIn]': defaultSurvey.map(s => s.question.id)})
    // eslint-disable-next-line
  }), [isLoadingSurvey])
  const {
    questions: unusedQuestions,
    refresh: refreshUnusedQuestions,
    isLoading: isLoadingUnusedQuestions
  } = useQuestions(unusedQuestionsParams)

  const {
    question: questionToUpdate,
    setQuestion: setQuestionToUpdate,
    update: updateQuestion,
    isLoading: isUpdatingQuestion
  } = useQuestion()

  return (
    <React.Fragment>

      <UpdateQuestionModal
        question={questionToUpdate}
        setQuestionToUpdate={setQuestionToUpdate}
        update={() =>
          updateQuestion()
            .then(() => setQuestionToUpdate(null))
            .catch(() => {})
            .finally(() => {
              refreshSurvey().catch()
              refreshUnusedQuestions().catch()
            })
        }
        isLoading={isUpdatingQuestion}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <QuestionsTableCard
              title="Encuesta común"
              questions={(defaultSurvey || []).map(s => s.question)}
              setQuestionToUpdate={setQuestionToUpdate}
              buttons={[{
                children: 'Guardar orden',
                disabled: !orderHasChanged,
                onClick: () => updateSurvey().then(() => {
                  refreshSurvey().catch()
                  setOrderHasChanged(false)
                })
              }]}
              customActions={(question, i) =>
                [
                  {
                    icon: <i class={`fas fa-arrow-up ${i > 0 ? "text-default" : ""}`}/>,
                    onClick: () => {
                      if(i > 0) {
                        setDefaultSurvey(survey => {
                          const surveyCopy = _.cloneDeep(survey)
                          const questionCopy = surveyCopy.splice(i, 1)[0]
                          surveyCopy.splice(i - 1, 0, questionCopy)
                          return surveyCopy
                        })
                        setOrderHasChanged(true)
                      }
                    },
                    description: "Mover arriba"
                  },
                  {
                    icon: <i class={`fas fa-arrow-down ${i < defaultSurvey.length - 1 ? "text-default" : ""}`}/>,
                    onClick: () => {
                      if(i < defaultSurvey.length-1) {
                        setDefaultSurvey(survey => {
                          const surveyCopy = _.cloneDeep(survey)
                          const questionCopy = surveyCopy.splice(i, 1)[0]
                          surveyCopy.splice(i + 1, 0, questionCopy)
                          return surveyCopy
                        })
                        setOrderHasChanged(true)
                      }
                    },
                    description: "Mover abajo"
                  },
                  {
                    icon: <i className="fas fa-minus text-default"/>,
                    onClick: () => {
                      const surveyCopy = _.cloneDeep(defaultSurvey)
                      surveyCopy.splice(i, 1)
                      updateSurvey(surveyCopy).then(() => {
                        refreshSurvey().catch()
                        refreshUnusedQuestions().catch()
                      })
                    },
                    description: "Quitar de encuesta"
                  }
                ]
              }
              isLoading={isLoadingSurvey}
            />

            <QuestionsTableCard
              title="Preguntas ocultas"
              questions={unusedQuestions}
              buttons={[{
                children: <AddButtonChildren children="Nueva"/>,
                onClick: () => setQuestionToUpdate({})
              }]}
              customActions={question =>
                [{
                  icon: <i className="fas fa-plus text-default"/>,
                  onClick: () => {
                    const surveyCopy = _.cloneDeep(defaultSurvey)
                    surveyCopy.push({question})
                    updateSurvey(surveyCopy).then(() => {
                      refreshSurvey().catch()
                      refreshUnusedQuestions().catch()
                    })
                  },
                  description: "Quitar de encuesta"
                }]
              }
              setQuestionToUpdate={setQuestionToUpdate}
              setQuestionToCreate={setQuestionToUpdate}
              isLoading={isLoadingUnusedQuestions}
              allowCreation
            />

          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default DefaultSurvey;
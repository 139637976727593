import React from "react";
import { Modal } from "@general-backoffice/core";


const RemoveScreensaverModal = ({ screensaverToRemove, close, remove, isLoading }) => {
  return (
    <Modal.DeleteConfirm
      isOpen={!!screensaverToRemove}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete="¿Seguro?"
      descriptionDelete={`Está a punto de borrar un salvapantallas`}
      closeText="Cancelar"
      buttonDelete={{ children: "Eliminar", onClick: remove }}
    />
  );
}

export default RemoveScreensaverModal;
import React from "react";
import {useSelector} from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

import QRCodeCard from "../../../../components/qrCode/QRCodeCard";
import configuration from '../../../../config/configuration'

const { frontUrl } = configuration

const QRSection = () => {
  const town = useSelector(({ sessionState }) => sessionState.town)

  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col xs="auto" className="mx-auto">
            {town &&
              <QRCodeCard value={`${frontUrl}/${town.identifier}`} name={`${town.name}_qr`}/>
            }
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default QRSection;
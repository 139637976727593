import { useState } from "react";
import { notify } from "@general-backoffice/core";

import { screensaverRequests} from "../../../api/Api"
import getFileId from "../../utils/getFileId";
import handleError from "../../utils/handleError";


const useScreensaver = () => {
  const [screensaver, setScreensaver] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const update = () => {
    if (!screensaver || isLoading) return;
    setLoading(true)
    const { active, ...screensaverProps} = screensaver
    return submitScreensaver(screensaverProps)
      .finally(() =>
        setLoading(false)
      )
  }

  const remove = async () => {
    if (!screensaver || isLoading) return;
    setLoading(true)
    return deleteScreensaver(screensaver)
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    screensaver,
    setScreensaver,
    update,
    remove,
    isLoading
  }
}


export const submitScreensaver = async screensaver => {
  const isNew = screensaver?.id
  const errMsg = `Error al ${isNew ? 'crear' : 'modificar'} el salvapantallas`

  if (!screensaver) throw new Error(errMsg)
  try {
    const { id, town, image, ...screensaverProps } = screensaver

    const imageId = await getFileId(image)

    const body = {
      ...screensaverProps,
      image: imageId,
      town: town?.id || null
    }

    let res;
    if(!id) res = await screensaverRequests.create(body)
    else res = await screensaverRequests.update(id, body)

    notify.success(`Salvapantallas ${isNew ? 'añadido' : 'actualizado'} correctamente`)

    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}

export const deleteScreensaver = async screensaver => {
  const errMsg = "Error al eliminar el salvapantallas"
  if (!screensaver?.id) throw new Error(errMsg)
  try {
    await screensaverRequests.delete(screensaver.id)
    notify.success("Salvapantallas emininado correctamente")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export default useScreensaver;
import configuration from "../../config/configuration"

const { mediaUrl } = configuration;

const formatStrapi4Response = res => {
  // Is truthy object
  if(res && typeof res === 'object') {

    // Is array
    if(Array.isArray(res)) {
      return res.map(item => formatStrapi4Response(item))

      // Is strapi data object
    } else if('data' in res && typeof res.data === 'object') {
      const data = res?.data
      // Is null
      if(!data) return data
      // Is non empty array with attributes
      if (Array.isArray(data) && data.length && 'attributes' in data[0])
        return data.map(item => formatLocalizations(formatStrapi4Response({...item.attributes, id: item.id})))
      // Is object with attributes
      else if ('attributes' in data)
        return formatLocalizations(formatStrapi4Response({...data.attributes, id: data.id}))
      else return data

      //Is another type of object
    } else {
      const resCopy = {...res}
      return Object.entries(resCopy).reduce((r, [key, value]) => {
        //Field is object
        if(value && typeof value === 'object') {
          if(Array.isArray(value))
            r[key] = value.map(item => formatStrapi4Response(item))
          else
            r[key] = formatLocalizations(formatStrapi4Response(value))

        } else r[key] = key === 'url' ? mediaUrl + value : value
        return r
      }, {})

    }
  } return res
}

const formatLocalizations = item => {
  if(item && 'localizations' in item && Array.isArray(item.localizations)) {
    const {localizations, ...formattedValueProps} = item
    const locale = item.locale
    if (!localizations.length > 0) {
      item.localizations = {[locale]: formattedValueProps}
    } else {
      const localizationsKeys = localizations.map(l => Object.keys(l))
      const defaultLocale = Object.entries(formattedValueProps)
        .reduce((r, [key, value]) => {
          if (key !== 'locale' && localizationsKeys.some(l => l.includes(key))) {
            r[key] = value
            // if(key !== 'id') delete item[key]
          }
          return r
        }, {})

      item.localizations = localizations.reduce((r, {locale, ...l}) => {
        r[locale] = l
        return r
      }, {[locale]: defaultLocale})
    }
  }
  return item
}

export default formatStrapi4Response


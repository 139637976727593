import React from "react";
import { Modal, FormInput } from "@general-backoffice/core/index";
import { Row, Col } from "reactstrap";

import useForm from "../hooks/useForm";
import useLang from "../hooks/useLang";


const UpdateQuestionCard = ({ question, setQuestionToUpdate, update, isLoading }) => {
  const { id, locale, localizations } = question || {}

  const {
    lang,
    LangSelector,
    // isLoading: isLoadingLang
  } = useLang({
    loaded: !!question,
    locale,
    setLocale: locale => handleInputChange('locale', locale)
  })
  const text = localizations?.[lang]?.question

  const {
    handleInputChange,
    handleLocalizedInputEvent
  } = useForm(setQuestionToUpdate, lang)

  const handleSubmit = async (event) => {
    event.preventDefault()
    await update()
  }

  return (
    <Modal.FormContainer
      size='lg'
      title={!!id ? `Editar pregunta` : "Crear nueva pregunta"}
      isOpen={!!question}
      toggleOpen={() => setQuestionToUpdate(null)}
      closeText="Cancelar"
      buttonSave={{ children: "Guardar", disabled: isLoading }}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    >

      <Row className="justify-content-end mb--3">
        <Col>
          <FormInput.Input
            onChange={handleLocalizedInputEvent}
            value={text}
            name="question"
            label="Pregunta"
            disabled={isLoading}
          />
        </Col>
        <Col xs="auto" className="flex-center pt-1">
          <LangSelector />
        </Col>
      </Row>

    </Modal.FormContainer>

  );
}

export default UpdateQuestionCard
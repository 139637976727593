import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { notify } from "@general-backoffice/core";

import {galleryRequests, uploadFile} from "../../../api/Api"
import handleError from "../../utils/handleError";


const useGallery = (fieldName) => {
  const town = useSelector(({ sessionState }) => sessionState.town)
  const [gallery, setGallery] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [fieldName, town])

  const refresh = async () => {
    const gallery = town[fieldName]
    if(!gallery) {
      notify.error('Ha ocurrido un error al cargar la galería')
      return setGallery(null)
    }

    setLoading(true)
    loadGallery(gallery.id)
      .then(gallery => {
        setGallery(gallery)
        return gallery
      })
      .finally(() => {
        setLoading(false)
      })
  }


  const update = () => {
    if (!gallery || isLoading) return;
    return updateGallery(gallery)
      .finally(() =>
        setLoading(false)
      )
  }

  return {
    gallery,
    setGallery,
    refresh,
    update,
    isLoading
  }
}


export const loadGallery = async id => {
  try {
    return await galleryRequests.get(id)
  } catch (e) {
    const message = (e?.message || "Error al cargar la galería")
    notify.error(message)
    throw new Error(message)
  }
}

export const updateGallery = async gallery => {
  const errMsg = "Error al modificar la galería"
  if (!gallery) throw new Error(errMsg)
  try {
    const { id, banner, images } = gallery

    const bannerId = await getFileId(banner)
    const processedImages = await Promise.all((images || []).map(getFileId))

    const updatedGallery = await galleryRequests.update(id, {
      banner: bannerId,
      images: processedImages
    })

    notify.success("Galería actualizada correctamente")
    return updatedGallery
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}

const getFileId = async (fileField) => {
  if(fileField) {
    if('id' in fileField)
      return fileField.id
    else {
      const uploadedFiles = await uploadFile(fileField)
      return uploadedFiles[0].id
    }
  } return null
}


export default useGallery;
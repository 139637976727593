import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import { getLocales } from "../../api/Api"


const useLocales = () => {
  const [locales, setLocales] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    getLocales()
      .then(locales => {
        setLocales(locales.reduce((r, {code, isDefault}) => {
          if(isDefault) r.unshift(code)
          else r.push(code)
          return r
        }, []))
      })
      .catch(() => {
        setLocales([])
        notify.error('Hubo un error durante la carga de idiomas')
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return {
    locales,
    isLoading
  }
}

export default useLocales;
import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

// Components
import UpdateGallerySectionCard from "../../../../components/gallerySection/UpdateGallerySectionCard";
import useGallery from "../../../../components/gallerySection/hooks/useGallery";

const GallerySection = ({ fieldName }) => {
  const {
    gallery,
    setGallery,
    refresh: refreshGallery,
    update: updateGallery,
    isLoading: isUpdatingGallery
  } = useGallery(fieldName)

  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false} />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <UpdateGallerySectionCard
              gallery={gallery}
              setGallery={setGallery}
              update={() =>
                updateGallery()
                  .catch(() => null)
                  .finally(refreshGallery)
              }
              isLoading={isUpdatingGallery}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default GallerySection;
import { notify } from "@general-backoffice/core/index";

const notifyLoadingUpdate = (id, render, type, options = {}) => {
  return notify.update(id, {
    render,
    type,
    isLoading: false,
    autoClose: null,
    className: "animation-shake", ...options
  });
}

const notifyLoadingToError = (id, render, options = {}) => notifyLoadingUpdate(id, render, "error", options)
const notifyLoadingToSuccess = (id, render, options = {}) => notifyLoadingUpdate(id, render, "success", options)
const notifyLoadingToWarning = (id, render, options = {}) => notifyLoadingUpdate(id, render, "warning", options)
const notifyLoadingToDefault = (id, render, options = {}) => notifyLoadingUpdate(id, render, "default", options)
const notifyLoadingToInfo = (id, render, options = {}) => notifyLoadingUpdate(id, render, "info", options)
const notifyLoadingToLoading = (id, render, options = {}) => notify.update(id, { render, ...options })

export {
  notifyLoadingUpdate,
  notifyLoadingToError,
  notifyLoadingToSuccess,
  notifyLoadingToWarning,
  notifyLoadingToDefault,
  notifyLoadingToInfo,
  notifyLoadingToLoading
}
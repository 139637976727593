import React from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import { Col, Row } from "reactstrap";

import useFileInput from "../hooks/useFileInput";
import useForm from "../hooks/useForm";


const UpdateTownModal = ({ townToUpdate = [], setTownToUpdate, close, update, isLoading }) => {
  const { id, name, active, show_default_questions, web, banner, coordinates, managerEmail } = townToUpdate || {}
  const { file: bannerFile } = useFileInput(banner)

  const {
    handleInputEvent,
    handleInputChange
  } = useForm(setTownToUpdate)

  const handleSubmit = async (event) => {
    event.preventDefault()
    await update()
  }

  return (
    <Modal.FormContainer
      title={!!id ? `Editar ${name}` : "Crear nueva población"}
      isOpen={!!townToUpdate}
      toggleOpen={close}
      closeText="Cancelar"
      buttonSave={{ children: "Guardar", disabled: isLoading }}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    >
      <FormInput.Input
        type="text"
        onChange={handleInputEvent}
        value={name}
        name="name"
        label="Nombre"
        disabled={isLoading}
        required
      />

      <FormInput.Input
        type="email"
        onChange={handleInputEvent}
        value={managerEmail}
        name="managerEmail"
        label="Correo de administrador"
        disabled={isLoading || !!id}
        required
      />

      <FormInput.Switch
        onChange={handleInputEvent}
        checked={active}
        name="active"
        label="¿Población activa?"
        labels={["Sí", "No"]}
        disabled={isLoading}
      />

      <FormInput.Switch
        onChange={handleInputEvent}
        checked={show_default_questions}
        name="show_default_questions"
        label="Mostrar preguntas compartidas"
        labels={["Sí", "No"]}
        disabled={isLoading}
      />

      <FormInput.SingleDropzone
        label="Banner"
        accept="image/*"
        file={bannerFile}
        onDrop={file => handleInputChange('banner', file)}
        buttons={[
          (bannerFile && {
            children: <i className="fas fa-trash"/>,
            color: "danger",
            onClick: () => handleInputChange('banner', null)
          })
        ]}
        disabled={isLoading}
      />

      <FormInput.Input
        type="text"
        onChange={handleInputEvent}
        value={web}
        name="web"
        label="Web"
        disabled={isLoading}
      />

      <Row>
        <Col>
          <FormInput.Input
            type="number"
            step="any"
            min={0}
            max={90}
            onChange={handleInputEvent}
            value={coordinates?.latitude}
            name="coordinates.latitude"
            label="Latitud"
            disabled={isLoading}
            required
          />
        </Col>
        <Col>
          <FormInput.Input
            type="number"
            step="any"
            min={0}
            max={90}
            onChange={handleInputEvent}
            value={coordinates?.longitude}
            name="coordinates.longitude"
            label="Longitud"
            disabled={isLoading}
            required
          />
        </Col>
      </Row>

    </Modal.FormContainer>
  );
}

export default UpdateTownModal;
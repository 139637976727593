import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import { defaultSurveyRequests } from "../../../api/Api"

import { notifyLoadingToSuccess } from "../../utils/notifyLoadingUpdate";
import handleError from "../../utils/handleError";


const useDefaultSurvey = params => {
  const [defaultSurvey, setDefaultSurvey] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [params])

  const refresh = async () => {
    if (isLoading) return;
    setLoading(true)
    return loadDefaultSurvey(params)
      .then(questions => {
        setDefaultSurvey(questions)
        return questions
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const update = newSurvey => {
    if (isLoading) return;
    setLoading(true)
    return updateTownSurvey(newSurvey || defaultSurvey)
      .then(() => refresh)
      .finally(() =>
        setLoading(false)
      )
  }

  return {
    defaultSurvey,
    setDefaultSurvey,
    update,
    refresh,
    isLoading
  }
}

export const loadDefaultSurvey = async (params = {}) => {
  try {
    const res = await defaultSurveyRequests.get({
      ...params,
      'populate[questions][populate][question][populate][answers][populate]': true,
      'populate[questions][populate][question][populate][localizations][populate]': true
    })
    return res?.questions
  } catch (e) {
    const message = (e?.message || "Error al cargar la encuesta")
    notify.error(message)
    throw new Error(message)
  }
}

export const updateTownSurvey = async survey => {
  const notification = notify.loading("Actualizando encuesta...")
  try {
    await defaultSurveyRequests.update(
      {
        questions: survey.filter(q => !!q.question)
          .map(q => ({
            id: q?.id,
            question: q.question?.id || q.question
          }))
      }
    )
    notifyLoadingToSuccess(notification, `Encuesta actualizada correctamente`)
  } catch (e) {
    handleError(e?.message || 'Error al actualizar la encuesta', notification)
  }
}



export default useDefaultSurvey;
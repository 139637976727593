import React from "react";
import { Modal } from "@general-backoffice/core";


const DeleteArticleModal = ({ article, close, remove, isLoading }) => {
  return (
    <Modal.DeleteConfirm
      isOpen={!!article}
      isLoading={isLoading}
      toggleOpen={close}
      titleDelete="¿Seguro?"
      descriptionDelete={`Está a punto de eliminar el articulo "${article?.title}"`}
      closeText="Cancelar"
      buttonDelete={{ children: "Eliminar", onClick: remove }}
    />
  );
}

export default DeleteArticleModal;
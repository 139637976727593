import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

// Components
import useTowns from "../../../../components/towns/hooks/useTowns";
import useTown from "../../../../components/towns/hooks/useTown";
import TownsTableCard from "../../../../components/towns/TownsTableCard"
import UpdateTownModal from "../../../../components/towns/UpdateTownModal";



const Towns = () => {
  const {
    towns,
    refresh: refreshTowns,
    isLoading: isLoadingTowns
  } = useTowns()

  const {
    town: townToUpdate,
    setTown: setTownToUpdate,
    update: updateTown,
    isLoading: isUpdatingTown
  } = useTown()

  return (
    <React.Fragment>

      <UpdateTownModal
        townToUpdate={townToUpdate}
        setTownToUpdate={setTownToUpdate}
        close={() => setTownToUpdate(null)}
        update={async () => {
          return updateTown()
            .then(async () => {
              await refreshTowns()
              await setTownToUpdate(null)
            })
            .catch(() => null)
        }}
        isLoading={isLoadingTowns || isUpdatingTown}
      />

      <Header.BreadCrumb color="secondary" isDark={false}/>
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <TownsTableCard
              screensavers={towns}
              setScreensaverToUpdate={setTownToUpdate}
              isLoading={isLoadingTowns}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Towns;
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { notify } from "@general-backoffice/core";

import { articleRequests } from "../../../api/Api"


const useArticles = () => {
  const town = useSelector(({ sessionState }) => sessionState.town)
  const [articles, setArticles] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [town])

  const refresh = async () => {
    setLoading(true)
    loadArticles(town.id)
      .then(articles => {
        setArticles(articles)
        return articles
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    articles,
    refresh,
    isLoading
  }
}


export const loadArticles = async townId => {
  try {
    return await articleRequests.getList(townId)
  } catch (e) {
    const message = (e?.message || "Error al cargar los artículos")
    notify.error(message)
    throw new Error(message)
  }
}

export default useArticles;
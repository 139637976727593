import {uploadFile} from "../../api/Api";

const getFileId = async image => {
  if(!image)
    return null
  else if('id' in image)
    return image.id
  else {
    const uploadedFiles = await uploadFile(image)
    return uploadedFiles[0].id
  }
}

export default getFileId
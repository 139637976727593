import React, { useState } from "react";
import { Container } from "reactstrap";
import { SidebarContainer, RoutesInside, Footer, Header, useFinalRoutes } from "@general-backoffice/core";
import { useDispatch, useSelector } from "react-redux";

import { logout } from "../../redux/reducers/session/actions";
import UserTownsModal from "../../components/towns/UserTownsModal";
import logo from "../../media/brand/logo.svg"



const Dashboard = () => {
  const routes = useFinalRoutes();
  const { town } = useSelector(({ sessionState }) => sessionState)
  // const { managedTown } = useSelector((state) => state.sessionState.user)
  // const notifications = useSessionNotifications()
  const [isOpenSidebar, setOpenSidebar] = useState(true)
  const [isOpenTowns, setOpenTowns] = useState(false)
  const dispatch = useDispatch();

  const sessionOptions = [
    {
      title: "Población",
      onClick: () => setOpenTowns(true),
      icon: "fas fa-dice-d6",
      // useDivider: true
    },
    {
      title: "Logout",
      onClick: () => dispatch(logout()),
      icon: "fas fa-running",
      useDivider: true
    }
  ]

  // const ntCount = notifications?.length || [];
  // const notificationsHeader = ntCount > 0 ? `Tienes ${ntCount} ${ntCount === 1 ? "notificación pendiente" : "notificaciones pendientes"}` : "No tienes notificaciones pendientes"

  return (
    <React.Fragment>
      <UserTownsModal isOpen={isOpenTowns} setOpen={setOpenTowns}/>
      <Container fluid className="p-0 m-0 min-vh-100">
        <SidebarContainer
          routes={routes}
          isOpen={isOpenSidebar}
          setOpen={setOpenSidebar}
          logoSrc={logo}
        >
          <Header.Session
            isOpen={isOpenSidebar}
            setOpen={setOpenSidebar}
            sessionName={town?.name}
            // notifications={notifications || []}
            isDark={true}
            color="primary"
            sessionOptions={sessionOptions}
            sessionOptionsHeader="¡Bienvenido!"
            // notificationsHeader={notificationsHeader}
          />
          <RoutesInside/>
          <Footer.Session/>
        </SidebarContainer>
      </Container>
    </React.Fragment>
  );
}

export default Dashboard;
import { useState } from "react";
import { notify } from "@general-backoffice/core";

import { questionRequests } from "../../../api/Api"
import handleError from "../../utils/handleError";
import {submitLocalizations} from "../../utils/localizations";


const useQuestion = () => {
  const [question, setQuestion] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const update = () => {
    if (!question || isLoading) return;
    setLoading(true)
    return submitQuestion(question)
      .finally(() =>
        setLoading(false)
      )
  }

  const remove = async () => {
    if (!question || isLoading) return;
    setLoading(true)
    return deleteQuestion(question)
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    question,
    setQuestion,
    update,
    remove,
    isLoading
  }
}


export const submitQuestion = async question => {
  const isNew = !question?.id
  const errMsg = `Error al ${isNew ? 'crear' : 'modificar'} el salvapantallas`

  if (!question) throw new Error(errMsg)
  try {
    const { id, localizations, ...body } = question

    delete body.answers

    console.log('AQUIIIIII', question, isNew)

    // Create or update base entry
    const res = isNew ?
      await questionRequests.create(body)
      :
      await questionRequests.update(id, body)

    // Update or create every localization needed
    const defaultId = isNew ? res.id : id
    await submitLocalizations(localizations, defaultId, questionRequests.update, questionRequests.localize)

    notify.success(`Pregunta ${isNew ? 'añadida' : 'actualizada'} correctamente`)
    return res
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}

export const deleteQuestion = async question => {
  const errMsg = "Error al eliminar la pregunta"
  if (!question?.id) throw new Error(errMsg)
  try {
    await questionRequests.delete(question.id)
    notify.success("Pregunta emininada correctamente")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export default useQuestion;
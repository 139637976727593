import { useEffect, useState } from "react";

import getFileFromUrl from "../utils/getFileFromUrl";


const useFileInput = inputFile => {
  const [file, setFile] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    processFile(inputFile)
      .then(setFile)
      .finally(() =>
        setIsLoading(false)
      )
  }, [inputFile])

  return {
    file,
    isLoading
  }
}

export const processFile = async (inputFile, onError) => {
  if(!inputFile) return null
  else if(inputFile?.id) {
    const { url, name, mime } = inputFile
    return await getFileFromUrl(url, name, mime)
      .catch(() => {
        if(onError) onError() // handleInputChange('banner', null)
        return null
      })
  } else return inputFile

}

export default useFileInput;
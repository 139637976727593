import React, { useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { useSelector } from "react-redux";


import useScreensavers from "../../../../components/screensavers/hooks/useScreensavers";
import useScreensaver from "../../../../components/screensavers/hooks/useScreensaver";

// Components
import UpdateScreensaverModal from "../../../../components/screensavers/UpdateScreensaverModal";
import RemoveScreensaverModal from "../../../../components/screensavers/RemoveScreensaverModal";
import ScreensaversTableCard from "../../../../components/screensavers/ScreensaversTableCard";

const defaultScreensaver = {
  image: null,
  town: null,
  expiration_date: null
}

const Screensavers = () => {
  const { town, user } = useSelector(({ sessionState }) => sessionState)
  const isAdmin = user?.role === 'Admin'

  const {
    screensavers,
    refresh: refreshScreensavers,
    isLoading: isLoadingScreensavers
  } = useScreensavers()
  const {
    screensaver: screensaverToUpdate,
    setScreensaver: setScreensaverToUpdate,
    update: updateScreensaver,
    isLoading: isUpdatingScreensaver
  } = useScreensaver()
  const {
    screensaver: screensaverToRemove,
    setScreensaver: setScreensaverToRemove,
    remove: removeScreensaver,
    isLoading: isRemovingScreensaver
  } = useScreensaver()

  const { sharedScreensavers, townScreensavers } = useMemo(() =>
    screensavers ?
      screensavers.reduce((r, ss) => {
        if(ss.town) r.townScreensavers.push(ss)
        else r.sharedScreensavers.push(ss)
        return r
      }, {sharedScreensavers: [], townScreensavers: []})
    :
      {}
  , [screensavers])

  return (
    <React.Fragment>

      <UpdateScreensaverModal
        screensaverToUpdate={screensaverToUpdate}
        setScreensaverToUpdate={setScreensaverToUpdate}
        close={() => setScreensaverToUpdate(null)}
        update={async () => {
          return updateScreensaver()
            .finally(async () => {
              await refreshScreensavers()
              await setScreensaverToUpdate(null)
            })
            .catch(() => null)
        }}
        isLoading={isUpdatingScreensaver || isLoadingScreensavers}
      />

      <RemoveScreensaverModal
        screensaverToRemove={screensaverToRemove}
        close={() => setScreensaverToRemove(null)}
        remove={() => {
          removeScreensaver()
            .finally(async () => {
              await refreshScreensavers()
              setScreensaverToRemove(null)
            })
            .catch(() => null)
        }}
        isLoading={isRemovingScreensaver || isLoadingScreensavers}
      />

      <Header.BreadCrumb color="secondary" isDark={false} />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <ScreensaversTableCard
              title={`Imagenes de ${town.name}`}
              screensavers={townScreensavers}
              defaultScreensaver={{...defaultScreensaver, town}}
              setScreensaverToUpdate={setScreensaverToUpdate}
              setScreensaverToRemove={setScreensaverToRemove}
              isLoading={isLoadingScreensavers}
            />
          </Col>
          <Col>
            <ScreensaversTableCard
              title="Imagenes compartidas"
              hasPermission={isAdmin}
              screensavers={sharedScreensavers}
              defaultScreensaver={defaultScreensaver}
              setScreensaverToUpdate={setScreensaverToUpdate}
              setScreensaverToRemove={setScreensaverToRemove}
              isLoading={isLoadingScreensavers}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Screensavers;
import React from "react";
import { Table, Modal, notify } from "@general-backoffice/core";
import { useDispatch, useSelector } from "react-redux";
import { setTown } from "../../redux/reducers/session/actions";


const UserTownsModal = ({ isOpen, setOpen }) => {
  const dispatch = useDispatch()
  const { town, towns } = useSelector(({ sessionState }) => sessionState)

  const columns = [
    {
      name: "Nombre",
      property: "name",
      isTh: true,
    },
    {
      name: "",
      formattedContent: ({ id }) => (id === town?.id) ? <i className="fas fa-check"/> : null,
    },
  ]

  const handleClick = newTown => {
    if (town?.id !== newTown.id) {
      dispatch(setTown(newTown))
      setOpen(false)
      notify(`Bienvenido a ${newTown.name}`)
    }
  };


  return (
    <Modal.Simple isOpen={isOpen} toggleOpen={() => setOpen(!isOpen)} title="Towns" closeText={"Cerrar"}>
      <div className="overflow-hidden mb-0 mx--4 mb--4">
        <Table
          columns={columns}
          rows={towns}
          onRowClick={handleClick}
          noContentMessage="No hay poblaciónes"
        />
      </div>
    </Modal.Simple>
  );
}

export default UserTownsModal;

const getDefaultTranslation = item => {
  const { locale, localizations } = item
  if(!localizations || !localizations.length) return null
  else if (localizations[locale]) return localizations[locale]
  else return localizations[0]
}


const changeLocalizedField = (item, locale, field, value) => {
  const newLocalization = {...item.localizations}

  //If not created create as empty object
  if (!newLocalization[locale]) newLocalization[locale] = {}
  //If created update with field updatedLocal
  if(item.id || !(locale === item.locale))
    newLocalization[locale].updatedLocale = true
  // If its new item default locale will be on create
  else {
    item[field] = value
    if(!newLocalization[locale]?.id)
      newLocalization[locale].id = true
  }

  newLocalization[locale][field] = value
  return {...item, localizations: newLocalization}
}

const handleLocalizedFieldChange = (locale, field, value, setter) => {
  setter(item => changeLocalizedField(item, locale, field, value))
}


const submitLocalizations = (localizations, defaultId, update, localize) =>
  Promise.all(Object.entries(localizations)
    .map(([locale, props]) => ({locale, ...props}))
    .map(({id: localeId, updatedLocale, ...localeProps}) => {
      if(!localeId)
        return localize(defaultId, localeProps)
      else if(updatedLocale)
        return update(localeId, localeProps)
      return null
    })
  )

export {
  getDefaultTranslation,
  changeLocalizedField,
  handleLocalizedFieldChange,
  submitLocalizations
}

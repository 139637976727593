import React from "react";
import {Button, Card, CardFooter} from "reactstrap";
import { QRCodeCanvas } from "qrcode.react";


const QRCodeCard = ({ value, name }) => {
  const downloadQR = () => {
    const url = document.getElementById("qr-code").toDataURL("image/png");
    const link = document.createElement('a');
    link.download = name + '.png';
    link.href = url;
    link.click();
  }

  return (
    <Card className="overflow-hidden">
      <QRCodeCanvas
        id="qr-code"
        value={value}
        size={500}
        style={{maxWidth: '100%'}}
        includeMargin
      />
      <CardFooter className="text-center">
        <Button color="primary" onClick={downloadQR}>
          Descargar
        </Button>
      </CardFooter>
    </Card>
  );
}

export default QRCodeCard;
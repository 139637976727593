import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import {surveyResponseRequests} from "../../../api/Api"

const useSurveyResponses = townId => {
  const [surveyReponses, setSurveyReponses] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [townId])

  const refresh = async () => {
    if (!townId || isLoading) return;
    setLoading(true)
    return loadAnsweredSurveys(townId)
      .then(answeredSurveys => {
        setSurveyReponses(answeredSurveys)
        return answeredSurveys
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    surveyReponses,
    refresh,
    isLoading
  }
}

export const loadAnsweredSurveys = async townId => {
  try {
    return await surveyResponseRequests.getList({ 'filters[town][id]': townId })
  } catch (e) {
    const message = (e?.message || "Error al cargar las respuestas a la encuesta")
    notify.error(message)
    throw new Error(message)
  }
}



export default useSurveyResponses;
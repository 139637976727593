import React, {useEffect} from "react";
import { Modal, FormInput } from "@general-backoffice/core";
import {useSelector} from "react-redux";
import moment from "moment";

import useFileInput from "../hooks/useFileInput";
import useForm from "../hooks/useForm";



const UpdateScreensaverModal = ({ screensaverToUpdate = [], setScreensaverToUpdate, close, update, isLoading }) => {
  const { user, town: sessionTown } = useSelector(({ sessionState }) => sessionState)
  const isAdmin = user?.role === 'Admin'

  const { id, image, expiration_date, town: ssTown } = screensaverToUpdate || {}
  const { file: imageFile } = useFileInput(image)

  const town = ssTown || sessionTown

  useEffect(() => {
    if(screensaverToUpdate && !isAdmin)
      handleInputChange('town', town)
    // eslint-disable-next-line
  }, [!!screensaverToUpdate, sessionTown])


  const { handleInputChange } = useForm(setScreensaverToUpdate)

  const handleSubmit = async (event) => {
    event.preventDefault()
    await update()
  }
  // const options = [
  //   { label: 'Compartido', value: null },
  //   { label: town?.name, value: town }
  // ]

  return (
    <Modal.FormContainer
      title={!!id ? `Editar salvapantallas` : "Crear salvapantallas"}
      isOpen={!!screensaverToUpdate}
      toggleOpen={close}
      closeText="Cancelar"
      buttonSave={{ children: "Guardar", disabled: isLoading }}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    >
      <FormInput.SingleDropzone
        label="Imagen"
        accept="image/*"
        file={imageFile}
        onDrop={file => handleInputChange('image', file)}
        disabled={isLoading}
        required
      />

      {/*{isAdmin &&*/}
      {/*  <FormInput.Select*/}
      {/*    label="Población"*/}
      {/*    onChange={({value}) => handleInputChange('town', value)}*/}
      {/*    options={options}*/}
      {/*    value={options[!ssTown ? 0 : 1]}*/}
      {/*    isDisabled={isLoading}*/}
      {/*    required*/}
      {/*  />*/}
      {/*}*/}

      <FormInput.DatePicker
        label="Fecha de expiración"
        description="Deja el campo en blanco no quieres que expire"
        onChange={date =>
          handleInputChange("expiration_date", date ? moment(date).format("YYYY-MM-DD") : null)
        }
        value={expiration_date ? moment(expiration_date) : null}
        timeFormat={false}
        disabled={isLoading}
      />


    </Modal.FormContainer>
  );
}

export default UpdateScreensaverModal;
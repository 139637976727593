import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, Form, Row, Col } from "reactstrap";
import { FormInput } from "@general-backoffice/core/index";

import useFileInput, { processFile } from "../hooks/useFileInput";
import useForm from "../hooks/useForm";


const UpdateGallerySectionCard = ({ gallery, setGallery, update, isLoading }) => {
  const { banner, images } = gallery || {}
  const { file: bannerFile } = useFileInput(banner)
  const [imagesFiles, setImagesFiles] = useState([])

  useEffect(() => {
    if(images)
      Promise.all(images.map(processFile))
        .then((images) =>
          setImagesFiles(images.filter(i => !!i)))
    else setImagesFiles([])
  }, [images])


  const { handleInputChange } = useForm(setGallery)

  const handleSubmit = async (event) => {
    event.preventDefault()
    await update()
  }


  return (
    <Card className="overflow-hidden">
      <CardBody>
        <Form id="sectionUpdateCardForm" onSubmit={handleSubmit}>

          <Row>
            <Col>
              <FormInput.SingleDropzone
                label="Banner"
                accept="image/*"
                file={bannerFile}
                onDrop={(file) => handleInputChange('banner', file)}
                buttons={[
                  (bannerFile && {
                    children: <i className="fas fa-trash"/>,
                    color: "danger",
                    onClick: () => handleInputChange('banner', null)
                  })
                ]}
                disabled={isLoading}
              />
            </Col>
            <Col>
              <FormInput.MultiDropzone
                label="Carteles"
                accept="image/*"
                files={imagesFiles}
                onDrop={files => handleInputChange('images', files)}
                buttons={[
                  {
                    children: <i className="fas fa-trash"/>,
                    color: "danger",
                    onClick: e => {
                      const imagesCopy = [...images]
                      imagesCopy.splice(e.target.index, 1)
                      handleInputChange('images', imagesCopy)
                    }
                  }
                ]}
                disabled={isLoading}
              />
            </Col>
          </Row>

        </Form>
      </CardBody>
      <CardFooter className="justify-content-end">
        <Button color="success" type="submit" form="sectionUpdateCardForm">
          Guardar
        </Button>
      </CardFooter>
    </Card>
  );
}

export default UpdateGallerySectionCard;
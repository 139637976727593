import {
  LOGIN,
  LOGOUT,
  CHANGE_TOWN
} from "./actions";
import Api from "../../../api/Api";
import { sessionVersion as currSessionVersion } from "../../../config/configuration";

const initialState = {
  token: null,
  user: {
    id: null,
    email: ""
  },
  town: null,
  towns: [],
  sessionVersion: null,
};

const state = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      Api.token = action.payload.token;
      Api.params = {}
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        sessionVersion: currSessionVersion
      };
    }
    case LOGOUT: {
      Api.token = null;
      Api.params = {};
      return initialState;
    }
    case CHANGE_TOWN: {
      const { town, towns } = action.payload
      return {
        ...state,
        ...(town ? {town} : {}),
        ...(towns ? {towns} : {}),
        sessionVersion: currSessionVersion
      };
    }
    default: {
      return state;
    }
  }
};

export default state;

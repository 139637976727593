import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card } from "reactstrap";
import moment from "moment";

import AddButtonChildren from "../shared/AddButtonChildren";


const status = [
  { status: "Expirado", color: "danger" },
  { status: "Activo", color: "success" }
]


const ScreensaversTableCard = ({ screensavers, setScreensaverToUpdate, setScreensaverToRemove, title, hasPermission = true, defaultScreensaver, isLoading }) => {

  const columns = [
    {
      name: "Imagen",
      formattedContent: ({ image }) => <TableOutput.Image src={image?.url}/>,
      isTh: true
    },
    {
      name: "Activa",
      formattedContent: ({ expired }) => <TableOutput.Status {...(expired ? status[0] : status[1])}/>
    },
    {
      name: "Fecha de expiración",
      formattedContent: ({ expiration_date }) =>
        expiration_date && <TableOutput.TitleSubtitle title={moment(expiration_date).format('DD/MM/YYYY')}/>
    }
  ]

  if(hasPermission) columns.push({
    name: "",
    formattedContent: (screensaver) =>
      <TableOutput.CustomActions
        actions={ [
          {
            icon: <i className="fas fa-edit"/>,
            onClick: () => setScreensaverToUpdate({ ...screensaver }),
            description: "Editar salvapantallas"
          },
          {
            icon: <i className="fas fa-trash"/>,
            onClick: () => setScreensaverToRemove({ ...screensaver }),
            description: "Eliminar salvapantallas"
          }
        ]}
      />
  })


  const activeScreensavers = (screensavers || []).filter(ss => !ss.expired).length

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title={title}
        subtitle={`Total activos: ${activeScreensavers}`}
        buttons={hasPermission ?
          [{
            children: <AddButtonChildren children={"Nueva"}/>,
            onClick: () => setScreensaverToUpdate(defaultScreensaver)
          }]
        : []
        }
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={screensavers}
        onRowClick={screensaver => setScreensaverToUpdate({ ...screensaver })}
        isLoading={isLoading}
        noContentMessage="No hay salvapantallas"
        rowHeight={81}
      />
    </Card>
  );
}

export default ScreensaversTableCard;
import React, {useMemo, useState} from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";
import { StatsCard } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import _ from "lodash";

import useTownSurvey from "../../../../components/questions/hooks/useTownSurvey";
import useQuestions from "../../../../components/questions/hooks/useQuestions";
import useQuestion from "../../../../components/questions/hooks/useQuestion";
import useSurveyResponses from "../../../../components/questions/hooks/useSurveyResponses";


// Components
import QuestionsTableCard from "../../../../components/questions/QuestionsTableCard";
import UpdateQuestionModal from "../../../../components/questions/UpdateQuestionModal";
import AddButtonChildren from "../../../../components/shared/AddButtonChildren";
import useDefaultSurvey from "../../../../components/questions/hooks/useDefaultSurvey";


const Survey = () => {
  const town = useSelector(({ sessionState }) => sessionState.town)

  const defaultSurveyParams = useMemo(() =>
    town?.id && {'populate[questions][populate][question][populate][answers][filters][survey_response][town][id][$eq]': town?.id}
  , [town?.id])
  const {
    defaultSurvey,
    isLoading: isLoadingDefaultSurvey
  } = useDefaultSurvey(defaultSurveyParams)
  const usesDefaultQuestions = town?.show_default_questions

  const {
    townSurvey,
    setTownSurvey,
    update: updateSurvey,
    refresh: refreshSurvey,
    isLoading: isLoadingSurvey
  } = useTownSurvey(town.id)
  const [orderHasChanged, setOrderHasChanged] = useState(false)

  const unusedQuestionsParams = useMemo(() => ({
    populate: ['answers', 'localizations'],
    'filters[town][id][$eq]': town?.id,
    ...(townSurvey && {'filters[id][$notIn]': townSurvey.map(s => s.question.id)})
  }), [town?.id, townSurvey])
  const {
    questions: unusedQuestions,
    refresh: refreshUnusedQuestions,
    isLoading: isLoadingUnusedQuestions
  } = useQuestions(unusedQuestionsParams)

  const { surveyReponses } = useSurveyResponses(town.id)

  const {
    question: questionToUpdate,
    setQuestion: setQuestionToUpdate,
    update: updateQuestion,
    // remove: removeQuestion,
    isLoading: isUpdatingQuestion
  } = useQuestion()

  return (
    <React.Fragment>

      <UpdateQuestionModal
        question={questionToUpdate}
        setQuestionToUpdate={setQuestionToUpdate}
        update={() =>
          updateQuestion()
            .then(() => setQuestionToUpdate(null))
            .catch(() => {})
            .finally(() => {
              refreshSurvey().catch()
              refreshUnusedQuestions().catch()
            })
        }
        isLoading={isUpdatingQuestion}
      />

      <Header.BreadCrumb color="secondary" isDark={false}>
        <Col xs={12} md={6} xl={4}>
          <StatsCard.Number
            color="primary"
            number={surveyReponses?.length || 0}
            suffix={` respuesta${surveyReponses?.length > 1 ? 's' : ''}`}
            title="Encuestas respondidas"
            icon="fas fa-poll-h"
          />
        </Col>
      </Header.BreadCrumb>
      <Container className="mt--6" fluid>
        <Row>
          <Col>

            {usesDefaultQuestions &&
              <QuestionsTableCard
                title="Encuesta común"
                questions={(defaultSurvey || []).map(s => s.question)}
                isLoading={isLoadingDefaultSurvey}
              />
            }

            <QuestionsTableCard
              title="Encuesta"
              questions={(townSurvey || []).map(s => s.question)}
              setQuestionToUpdate={setQuestionToUpdate}
              buttons={[{
                children: 'Guardar orden',
                disabled: !orderHasChanged,
                onClick: () => updateSurvey().then(() => {
                  refreshSurvey().catch()
                  setOrderHasChanged(false)
                })
              }]}
              customActions={(question, i) =>
                [
                  {
                    icon: <i class={`fas fa-arrow-up ${i > 0 ? "text-default" : ""}`}/>,
                    onClick: () => {
                      if(i > 0) {
                        setTownSurvey(survey => {
                          const surveyCopy = _.cloneDeep(survey)
                          const questionCopy = surveyCopy.splice(i, 1)[0]
                          surveyCopy.splice(i - 1, 0, questionCopy)
                          return surveyCopy
                        })
                        setOrderHasChanged(true)
                      }
                    },
                    description: "Mover arriba"
                  },
                  {
                    icon: <i class={`fas fa-arrow-down ${i < townSurvey.length - 1 ? "text-default" : ""}`}/>,
                    onClick: () => {
                      if(i < townSurvey.length-1) {
                        setTownSurvey(survey => {
                          const surveyCopy = _.cloneDeep(survey)
                          const questionCopy = surveyCopy.splice(i, 1)[0]
                          surveyCopy.splice(i + 1, 0, questionCopy)
                          return surveyCopy
                        })
                        setOrderHasChanged(true)
                      }
                    },
                    description: "Mover abajo"
                  },
                  {
                    icon: <i className="fas fa-minus text-default"/>,
                    onClick: () => {
                      const surveyCopy = _.cloneDeep(townSurvey)
                      surveyCopy.splice(i, 1)
                      updateSurvey(surveyCopy).then(() => {
                        refreshSurvey().catch()
                        refreshUnusedQuestions().catch()
                      })
                    },
                    description: "Quitar de encuesta"
                  }
                ]
              }
              isLoading={isLoadingSurvey}
            />

            <QuestionsTableCard
              title="Preguntas ocultas"
              questions={unusedQuestions}
              buttons={[{
                children: <AddButtonChildren children="Nueva"/>,
                onClick: () => setQuestionToUpdate({ town: town.id })
              }]}
              customActions={question =>
                [{
                  icon: <i className="fas fa-plus text-default"/>,
                  onClick: () => {
                    const surveyCopy = _.cloneDeep(townSurvey)
                    surveyCopy.push({question})
                    updateSurvey(surveyCopy).then(() => {
                      refreshSurvey().catch()
                      refreshUnusedQuestions().catch()
                    })
                  },
                  description: "Quitar de encuesta"
                }]
              }
              setQuestionToUpdate={setQuestionToUpdate}
              setQuestionToCreate={setQuestionToUpdate}
              isLoading={isLoadingUnusedQuestions}
              allowCreation
            />

          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Survey;
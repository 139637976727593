import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import {townRequests} from "../../../api/Api"

import {notifyLoadingToSuccess} from "../../utils/notifyLoadingUpdate";
import handleError from "../../utils/handleError";


const useTownSurvey = townId => {
  const [townSurvey, setTownSurvey] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [townId])

  const refresh = async () => {
    if (!townId || isLoading) return;
    setLoading(true)
    return loadTownSurvey(townId)
      .then(questions => {
        setTownSurvey(questions)
        return questions
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const update = newSurvey => {
    if (!townId || isLoading) return;
    setLoading(true)
    return updateTownSurvey(townId, newSurvey || townSurvey)
      .then(() => refresh)
      .finally(() =>
        setLoading(false)
      )
  }

  return {
    townSurvey,
    setTownSurvey,
    update,
    refresh,
    isLoading
  }
}

export const loadTownSurvey = async townId => {
  try {
    const res = await townRequests.get(townId, { populate: ['questions.question.answers', 'questions.question.localizations'] })
    return res?.questions
  } catch (e) {
    const message = (e?.message || "Error al cargar la encuesta")
    notify.error(message)
    throw new Error(message)
  }
}

export const updateTownSurvey = async (townId, survey) => {
  const notification = notify.loading("Actualizando encuesta...")
  try {
    await townRequests.update(
      townId,
      {
        questions: survey.filter(q => !!q.question)
          .map(q => ({
            id: q?.id,
            question: q.question?.id || q.question
          }))
      }
    )
    notifyLoadingToSuccess(notification, `Encuesta actualizada correctamente`)
  } catch (e) {
    handleError(e?.message || 'Error al actualizar la encuesta', notification)
  }
}



export default useTownSurvey;
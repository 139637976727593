import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

import useArticles from "../../../../components/article/hooks/useArticles";
import useArticle from "../../../../components/article/hooks/useArticle";

// Components
import ArticlesTableCard from "../../../../components/article/ArticlesTableCard";
import UpdateArticleModal from "../../../../components/article/UpdateArticleModal";
import DeleteArticleModal from "../../../../components/article/DeleteArticleModal";


const Articles = () => {
  const {
    articles,
    refresh: refreshArticles,
    isLoading: isLoadingArticles
  } = useArticles()

  const {
    article: articleToUpdate,
    setArticle: setArticleToUpdate,
    update: updateArticle,
    isLoading: isUpdatingArticle
  } = useArticle()

  const {
    article: articleToDelete,
    setArticle: setArticleToDelete,
    remove: deleteArticle,
    isLoading: isDeletingArticle
  } = useArticle()

  return (
    <React.Fragment>

      <UpdateArticleModal
        article={articleToUpdate}
        setArticleToUpdate={setArticleToUpdate}
        update={() =>
          updateArticle()
            .then(() => setArticleToUpdate(null))
            .catch(() => {})
            .finally(refreshArticles)
        }
        isLoading={isUpdatingArticle}
      />

      <DeleteArticleModal
        article={articleToDelete}
        close={() => setArticleToDelete(null)}
        remove={() =>
          deleteArticle()
            .catch(() => null)
            .finally(() => {
              setArticleToDelete(null)
              refreshArticles()
            })
        }
        isLoading={isDeletingArticle}
      />

      <Header.BreadCrumb color="secondary" isDark={false} />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <ArticlesTableCard
              articles={articles}
              setArticleToUpdate={setArticleToUpdate}
              setArticleToDelete={setArticleToDelete}
              isLoading={isLoadingArticles}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Articles;
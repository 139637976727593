import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import moment from "moment";

import { screensaverRequests } from "../../../api/Api"


const useScreensavers = () => {
  const town = useSelector(({ sessionState }) => sessionState.town)
  const [screensavers, setScreensavers] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [town?.id])

  const refresh = async () => {
    setLoading(true)
    return loadScreensavers(town.id)
      .then(screensavers => {
        const formatedScreensavers = screensavers.map(ss => ({...ss, expired: moment().isAfter(ss.expiration_date)}))
        setScreensavers(formatedScreensavers)
        return formatedScreensavers
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    screensavers,
    refresh,
    isLoading
  }
}

export const loadScreensavers = async townId => {
  try {
    return await screensaverRequests.getList(townId)
  } catch (e) {
    const message = (e?.message || "Error al cargar las imagenes para el salvapantallas")
    notify.error(message)
    throw new Error(message)
  }
}



export default useScreensavers;
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { notify } from "@general-backoffice/core";

import {sectionRequests} from "../../../api/Api"
import getFileId from "../../utils/getFileId";
import handleError from "../../utils/handleError";


const useSection = (fieldName) => {
  const town = useSelector(({ sessionState }) => sessionState.town)
  const [section, setSection] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [fieldName, town])

  const refresh = async () => {
    const section = town[fieldName]
    if(!section) {
      notify.error('Ha ocurrido un error al cargar la sección')
      return setSection(null)
    }

    setLoading(true)
    loadSection(section.id)
      .then(section => {
        setSection(section)
        return section
      })
      .finally(() => {
        setLoading(false)
      })
  }


  const update = () => {
    if (!section || isLoading) return;
    return updateSection(section)
      .finally(() =>
        setLoading(false)
      )
  }

  return {
    section,
    setSection,
    refresh,
    update,
    isLoading
  }
}


export const loadSection = async id => {
  try {
    return await sectionRequests.get(id)
  } catch (e) {
    const message = (e?.message || "Error al cargar la sección")
    notify.error(message)
    throw new Error(message)
  }
}

export const updateSection = async section => {
  const errMsg = "Error al modificar la sección"
  if (!section) throw new Error(errMsg)
  try {
    const { id, banner, attached_file, localizations } = section

    console.log('111111111', attached_file)

    // Update non localized fields
    const bannerId = await getFileId(banner)
    const attachedFileId = await getFileId(attached_file)
    await sectionRequests.update(id, {
      banner: bannerId,
      attached_file: attachedFileId
    })
    console.log('2222222')

    // Update or create every localization needed
    await Promise.all(Object.entries(localizations)
      .map(([locale, props]) => ({locale, ...props}))
      .map(({id: localeId, updatedLocale, ...localeProps}) => {
        console.log({id: localeId, updatedLocale, localeProps})
        if(!localeId)
          return sectionRequests.localize(id, localeProps)
        else if(updatedLocale)
          return sectionRequests.update(localeId, localeProps)
        return null
      })
    )

    console.log('33333333333')
    notify.success("Sección actualizada correctamente")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export default useSection;
import React from "react";
import { Navigate } from "react-router";
import TextSection from "../../views/pages/dashboard/sections/TextSection";
import GallerySection from "../../views/pages/dashboard/sections/GallerySection";

const sectionsTranslations = {
  history: 'História',
  crest: 'Escudo',
  heritage: 'Patrimonio',
  local_celebrations: 'Fiestas patronales',
  culture: 'Cultura y tradición',
}

const galeriesTranslations = {
  tourism: 'Turismo y gastronomía',
  health: 'Salud y medioambiente',
  statistics: 'Datos estadísticos'
}

const subRoutes = [
  ...Object.entries(galeriesTranslations).map(([section, name]) => (
    {
      name: name,
      path: section.replace('_', '-'),
      showOnNav: true,
      component: <GallerySection fieldName={section} />
    }
  )),

  ...Object.entries(sectionsTranslations).map(([section, name]) => (
    {
      name: name,
      path: section.replace('_', '-'),
      showOnNav: true,
      component: <TextSection fieldName={section} />
    }
  )),
  {
    path: "*",
    index: true,
    component: <Navigate to={"/dashboard/sections/tourism"} replace={true}/>,
  }
]

export default subRoutes;
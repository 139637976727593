import React from "react";
import { notify, Page } from "@general-backoffice/core";
import { useDispatch } from "react-redux";
import { townRequests, login as apiLogin } from "../../../api/Api";
import {login, setTown, logout} from "../../../redux/reducers/session/actions"

const Login = () => {
  const dispatch = useDispatch()

  const onSubmit = async ({ email, password }) => {
    try {
      const { jwt, user } = await apiLogin(email, password)
      const { managedTown, role } = user

      dispatch(login(jwt, {...user, role: role.name}))

      if(role.name === 'Admin') {
        const towns = await townRequests.getList()
        dispatch(setTown(towns?.[0], towns))
      } else if(managedTown) {
        const town = await townRequests.get(managedTown.id)
        if(town) dispatch(setTown(town, [town]))
        else dispatch(logout())
      } else throw new Error('No tienes acceso a ninguna población')

      notify.success("¡Bienvenido!", { icon: <i className="fas fa-handshake ni-lg"/> })
    } catch (e) {
      notify.error(e)
      dispatch(logout())
    }
  }

  return <Page.Login
    title="¡Bienvenido!"
    lead="Por favor, inicia sesión para acceder al panel de control"
    emailPlaceholder="Email"
    passwordPlaceholder="Contraseña"
    rememberLabel="Recuerdame"
    submitText="Inicia sesión"
    onSubmit={onSubmit}
    invalidEmailFeedbackText="Introduce un email válido"
    invalidPasswordFeedbackText="Introduce una contraseña válida"
  />;
}

export default Login;
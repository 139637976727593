import { RestApi } from "@general-backoffice/core";
import moment from "moment";

import configuration from "./../config/configuration"
import formatStrapi4Response from "./utils/formatStrapi4Response";

const { apiUrl } = configuration;


const Api = new RestApi({
  path: apiUrl,
  processResponse: ({data}) => formatStrapi4Response(data),
  processError: (e) => {throw e.response.data?.error?.message || e.message}
})



//  User Requests
export const login = async (identifier, password) => Api.post("/auth/local?[populate]=*", { identifier, password })
// export const me = async () => Api.get(`/users/me?[populate]=*`);
export const uploadFile = async file => {
  const form = new FormData()
  form.append('files', file)
  return Api.post(`/upload`, form);
}
export const getLocales = async () => Api.get(`/i18n/locales`);


//  Town Requests
const getTown = (id, params) => Api.get(`/towns/${id}`, { params: params || { populate: '*' }});
const getTowns = params => Api.get(`/towns`, { params: { populate: '*', ...params}});
const createTown = body => Api.post(`/towns`, {data: body});
const updateTown = (id, body) => Api.put(`/towns/${id}`, {data: body});

const townRequests = {
  get: getTown,
  getList: getTowns,
  create: createTown,
  update: updateTown
}


//  Article Requests
const getArticle = id => Api.get(`/articles/${id}`, { params: { populate: '*' }});
const getArticles = townId => Api.get(`/articles`, { params: { populate: '*', 'filters[town][id][$eq]': townId }});
const createArticle = body => Api.post(`/articles`, {data: body});
const updateArticle = (id, body) => Api.put(`/articles/${id}`, {data: body});
const localizeArticle = (id, body) => Api.post(`/articles/${id}/localizations`, body);
const deleteArticle = id => Api.delete(`/articles/${id}`);

const articleRequests = {
  get: getArticle,
  getList: getArticles,
  create: createArticle,
  update: updateArticle,
  localize: localizeArticle,
  delete: deleteArticle
}


//  Sections Requests
const getSection = id => Api.get(`/sections/${id}`, { params: { populate: 'localizations,banner,attached_file' }});
const updateSection = (id, body) => Api.put(`/sections/${id}`, {data: body});
const localizeSection = (id, body) => Api.post(`/sections/${id}/localizations`, body);

const sectionRequests = {
  get: getSection,
  update: updateSection,
  localize: localizeSection
}


//  Gallery Requests
const getGallery = id => Api.get(`/galleries/${id}`, { params: { populate: '*' }});
const updateGallery = (id, body) => Api.put(`/galleries/${id}`, {data: body});

const galleryRequests = {
  get: getGallery,
  update: updateGallery
}


//  Screensaver Requests
const getTownScreensavers = townId => Api.get(`/screensavers`, { params: {
    'filters[town][$or][0][id][$eq]': townId,
    'filters[town][$or][1][id][$null]': true,
    populate: '*'
}}).then(ss =>
  ss.sort(({expiration_date: e1}, {expiration_date: e2}) =>
    !e1 ?
      !e2 ? 0 : -1
    :
      !e2 ? 1 :
        moment(e1).isAfter(e2) ?
          -1 : 1
  )
);
const createScreensaver = body => Api.post(`/screensavers`, {data: body});
const updateScreensaver = (id, body) => Api.put(`/screensavers/${id}`, {data: body});
const deleteScreensaver = id => Api.delete(`/screensavers/${id}`);

const screensaverRequests = {
  getList: getTownScreensavers,
  create: createScreensaver,
  update: updateScreensaver,
  delete: deleteScreensaver
}


//  Questions Requests
const getQuestions = params => Api.get(`/questions`, {params});
const createQuestion = body => Api.post(`/questions`, {data: body});
const updateQuestion = (id, body) => Api.put(`/questions/${id}`, {data: body});
const localizeQuestion = (id, body) => Api.post(`/questions/${id}/localizations`, body);
const deleteQuestion = id => Api.delete(`/questions/${id}`);

const questionRequests = {
  getList: getQuestions,
  create: createQuestion,
  update: updateQuestion,
  localize: localizeQuestion,
  delete: deleteQuestion
}


//  SurveyResponse Requests
const getSurveyResponses = params => Api.get(`/survey-responses`, {params});

const surveyResponseRequests = {
  getList: getSurveyResponses
}


//  SurveyResponse Requests
const getDefaultSurvey = params => Api.get(`/default-survey`, {params});
const updateDefaultSurvey = body => Api.put(`/default-survey`, {data: body});

const defaultSurveyRequests = {
  get: getDefaultSurvey,
  update: updateDefaultSurvey
}




export {
  townRequests,
  articleRequests,
  sectionRequests,
  galleryRequests,
  screensaverRequests,
  questionRequests,
  surveyResponseRequests,
  defaultSurveyRequests
}


export default Api;
import React from "react";
import { Modal, FormInput, FormDecoration } from "@general-backoffice/core/index";
import { Row, Col } from "reactstrap";
import moment from "moment";

import useFileInput from "../hooks/useFileInput";
import useForm from "../hooks/useForm";
import useLang from "../hooks/useLang";
import { changeLocalizedField } from "../utils/localizations";


const UpdateArticleCard = ({ article, setArticleToUpdate, update, isLoading }) => {
  const { id, banner, attached_file, locale, publishing_date, localizations } = article || {}
  const { file: bannerFile } = useFileInput(banner)
  const { file: pdfFile } = useFileInput(attached_file)


  const {
    lang,
    LangSelector,
    // isLoading: isLoadingLang
  } = useLang({
    loaded: !!article,
    locale,
    setLocale: locale => handleInputChange('locale', locale)
  })
  const { title, text } = localizations?.[lang] || {}

  const {
    handleInputChange,
    handleLocalizedInputEvent
  } = useForm(setArticleToUpdate, lang)


  const handleSubmit = async (event) => {
    event.preventDefault()
    await update()
  }

  const pdfLink = attached_file?.url

  return (
    <Modal.FormContainer
      size="lg"
      title={!!id ? `Editar artículo` : "Crear nuevo artículo"}
      isOpen={!!article}
      toggleOpen={() => setArticleToUpdate(null)}
      closeText="Cancelar"
      buttonSave={{ children: "Guardar", disabled: isLoading }}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    >

      <Row className="justify-content-end mb--3">
        <Col>
          <FormInput.Input
            onChange={handleLocalizedInputEvent}
            value={title}
            name="title"
            label="Título"
            disabled={isLoading}
          />
        </Col>
        <Col xs="auto" className="flex-center pt-1">
          <LangSelector />
        </Col>

        <Col xs={12}>
          <FormInput.Quill key={lang}
            value={text || ""}
            onChange={value =>
              setArticleToUpdate(article =>
                !!article && changeLocalizedField(article, lang, 'text', value)
              )
            }
            disabled={isLoading}
          />
        </Col>
      </Row>

      <FormDecoration.Line/>

      <Row>
        <Col xs={12} lg={6}>
          <FormInput.SingleDropzone
            label="Banner"
            accept="image/*"
            file={bannerFile}
            onDrop={(file) => handleInputChange('banner', file)}
            buttons={[
              (bannerFile && {
                children: <i className="fas fa-trash"/>,
                color: "danger",
                onClick: () => handleInputChange('banner', null)
              })
            ]}
            disabled={isLoading}
          />
        </Col>
        <Col xs={12} lg={6}>
          <FormInput.SingleDropzone
            label="Pdf adjunto"
            accept=".pdf"
            file={pdfFile}
            onDrop={(file) => handleInputChange('attached_file', file)}
            buttons={[
              (pdfFile && {
                children: <i className="fas fa-trash"/>,
                color: "danger",
                onClick: () => handleInputChange('attached_file', null)
              })
            ]}
            disabled={isLoading}
            description={pdfLink &&
              <div className="w-100 overflow-hidden pl-1" style={{ textOverflow: 'ellipsis' }}>
                <a href={pdfLink} target="_blank" rel="noreferrer">Ver archivo</a>
              </div>
            }
          />
        </Col>

        <Col style={{ maxWidth: 350 }}>
          <FormInput.DatePicker
            label="Fecha de publicación"
            description="Dejando el campo en blanco el aparecerá en la web"
            onChange={date => {
              if(date && moment(date).isValid)
                handleInputChange("publishing_date", moment(date).format("YYYY-MM-DD"))
            }}
            value={publishing_date ? moment(publishing_date) : null}
            timeFormat={false}
            disabled={isLoading}
          />
        </Col>
      </Row>

    </Modal.FormContainer>

  );
}

export default UpdateArticleCard
import React from 'react';
import ReactDOM from 'react-dom';
import moment from "moment";
import 'moment/locale/es';
import App from './App';

import "@general-backoffice/core/lib/assets/vendor/nucleo/css/nucleo.css"
import './styles/index.css';

moment.locale("es");

ReactDOM.render(<App />, document.getElementById('root'));
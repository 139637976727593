import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Header } from "@general-backoffice/core/index";

// Components
import UpdateSectionCard from "../../../../components/section/UpdateSectionCard";
import useSection from "../../../../components/section/hooks/useSection";
import useLocales from "../../../../components/hooks/useLocales";

const TextSection = ({ fieldName }) => {
  const {
    section,
    setSection,
    refresh: refreshSection,
    update: updateSection,
    isLoading: isUpdatingSection
  } = useSection(fieldName)

  const {
    locales,
    isLoading: isLoadingLocales
  } = useLocales()

  return (
    <React.Fragment>
      <Header.BreadCrumb color="secondary" isDark={false} />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <UpdateSectionCard
              section={section}
              locales={locales}
              setSection={setSection}
              update={() =>
                updateSection()
                  .catch(() => null)
                  .finally(refreshSection)
              }
              isLoading={isUpdatingSection || isLoadingLocales}
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default TextSection;
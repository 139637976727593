import React, {useState, useMemo, useEffect} from "react";
import { Button, Card, CardBody, CardFooter, Form, Row, Col } from "reactstrap";
import { FormInput, FormDecoration } from "@general-backoffice/core/index";

import useFileInput from "../hooks/useFileInput";
import useForm from "../hooks/useForm";




const UpdateSectionCard = ({ section, setSection, update, locales, isLoading }) => {
  const { banner, attached_file, localizations, locale } = section || {}
  const { file: bannerFile } = useFileInput(banner)
  const { file: pdfFile } = useFileInput(attached_file)

  const [curLang, setCurLang] = useState()
  const langOptions = useMemo(() => locales.map(l => ({label: l, value: l})), [locales])
  useEffect(() => {
    if(locales.length)
      setCurLang(locale ||locales[0])
  }, [locales, locale])

  const localization = localizations?.[curLang]
  const { text } = localization || {}

  const { handleInputChange, handleLocalizedInputEvent } = useForm(setSection, curLang)

  const handleSubmit = async (event) => {
    event.preventDefault()
    await update()
  }

  const pdfLink = attached_file?.url

  return (
    <Card className="overflow-hidden">
      <CardBody>
        <Form id="sectionUpdateCardForm" onSubmit={handleSubmit}>

          <Row className="justify-content-end mb--3">
            <Col xs="auto mb--5" style={{zIndex: 1}}>
              <FormInput.Select
                onChange={({value}) => setCurLang(value)}
                options={langOptions}
                value={langOptions && langOptions.find(({value}) => value === curLang)}
                placeholder="Cargando..."
                isDisabled={isLoading}
              />
            </Col>
            <Col xs={12}>
              <FormInput.Input
                type="textarea"
                rows="15"
                onChange={handleLocalizedInputEvent}
                value={text}
                name="text"
                label="Texto"
                disabled={isLoading}
              />
            </Col>
          </Row>

          <FormDecoration.Line/>

          <Row>
            <Col>
              <FormInput.SingleDropzone
                label="Banner"
                accept="image/*"
                file={bannerFile}
                onDrop={(file) => handleInputChange('banner', file)}
                buttons={[
                  (bannerFile && {
                    children: <i className="fas fa-trash"/>,
                    color: "danger",
                    onClick: () => handleInputChange('banner', null)
                  })
                ]}
                disabled={isLoading}
              />
            </Col>
            <Col>
              <FormInput.SingleDropzone
                label="Pdf adjunto"
                accept=".pdf"
                file={pdfFile}
                onDrop={(file) => handleInputChange('attached_file', file)}
                buttons={[
                  (pdfFile && {
                    children: <i className="fas fa-trash"/>,
                    color: "danger",
                    onClick: () => handleInputChange('attached_file', null)
                  })
                ]}
                disabled={isLoading}
                description={pdfLink &&
                  <div className="w-100 overflow-hidden pl-1" style={{ textOverflow: 'ellipsis' }}>
                    <a href={pdfLink} target="_blank" rel="noreferrer">Ver archivo</a>
                  </div>
                }
              />

            </Col>
          </Row>

        </Form>
      </CardBody>
      <CardFooter className="justify-content-end">
        <Button color="success" type="submit" form="sectionUpdateCardForm">
          Guardar
        </Button>
      </CardFooter>
    </Card>
  );
}

export default UpdateSectionCard;
import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";

import {questionRequests} from "../../../api/Api"


const useQuestions = params => {
  const [questions, setQuestions] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [params])

  const refresh = async () => {
    setLoading(true)
    return loadQuestions(params)
      .then(questions => {
        setQuestions(questions)
        return questions
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    questions,
    refresh,
    isLoading
  }
}

export const loadQuestions = async params => {
  try {
    console.log('params', params)
    return await questionRequests.getList(params)
  } catch (e) {
    const message = (e?.message || "Error al cargar las preguntas")
    notify.error(message)
    throw new Error(message)
  }
}



export default useQuestions;
import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { Card } from "reactstrap";
import AddButtonChildren from "../shared/AddButtonChildren";

const status = [
  { status: "Activo", color: "success" },
  { status: "Deshabilitado", color: "danger" }
]

const showCommonQuestions = [
  { status: "Incluir compartidas", color: "success" },
  { status: "Solo de población", color: "danger" }
]

const defaultTown = {
  name: "",
  active: true,
  show_default_questions: true,
  web: null,
  banner: null,
  coordinates: {
    latitude: null,
    longitude: null
  }
}

const TownsTableCard = ({ screensavers, setScreensaverToUpdate, isLoading }) => {

  const columns = [
    {
      name: "Población",
      property: "name",
      isTh: true
    },
    {
      name: "Web",
      property: "web",
    },
    {
      name: "Activa",
      formattedContent: ({ active }) =>
        <TableOutput.Status {...(active ? status[0] : status[1])}/>
    },
    {
      name: "Preguntas compartidas",
      formattedContent: ({ show_default_questions }) =>
        <TableOutput.Status {...(show_default_questions ? showCommonQuestions[0] : showCommonQuestions[1])}/>
    },
    {
      name: "Banner",
      formattedContent: ({ banner }) => banner && <TableOutput.Image src={banner?.url}/>
    },
    {
      name: "",
      formattedContent: (town) => {
        return <TableOutput.CustomActions
          actions={[
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setScreensaverToUpdate({ ...town }),
              description: "Editar Club"
            },
            // {
            //   icon: <i className="fas fa-user-edit"/>,
            //   onClick: () => null,
            //   description: "Editar usuario"
            // }
          ]}
        />
      }
    }
  ]


  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title="Poblaciónes"
        subtitle={`Total: ${screensavers?.length || 0}`}
        buttons={[
          { children: <AddButtonChildren children="Población"/>, onClick: () => setScreensaverToUpdate(defaultTown) }
        ]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={screensavers}
        onRowClick={town => setScreensaverToUpdate({ ...town })}
        isLoading={isLoading}
        noContentMessage={"No hay poblaciónes"}
        rowHeight={81}
      />
    </Card>
  );
}

export default TownsTableCard;
const environment = process.env.NODE_ENV || "production";

const sessionVersion = "1.0.2";

const getConfiguration = () => {
  switch (environment) {
    case "development":
      return {
        // frontUrl: "https://infopoints.hi-services.es",
        // apiUrl: "http://localhost:1337/api",
        // mediaUrl: "http://localhost:1337",
        frontUrl: "https://infopoints.hi-services.es",
        apiUrl: "https://dashboard.hi-services.es/api/api",
        mediaUrl: "https://dashboard.hi-services.es/api",
      }
    default:
    case "production":
      return {
        frontUrl: "https://infopoints.hi-services.es",
        apiUrl: "https://dashboard.hi-services.es/api/api",
        mediaUrl: "https://dashboard.hi-services.es/api",
      }
  }
}

export default getConfiguration();
export { sessionVersion }
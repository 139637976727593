import { useEffect, useState } from "react";
import { notify } from "@general-backoffice/core";
import { useSelector } from "react-redux";

import { townRequests } from "../../../api/Api"


const useTowns = () => {
  const town = useSelector(({ sessionState }) => sessionState.town)
  const [towns, setTowns] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    refresh().catch(() => null)
    // eslint-disable-next-line
  }, [town?.id])

  const refresh = async () => {
    setLoading(true)
    return loadTowns()
      .then((towns) => {
        setTowns(towns)
        return towns
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return {
    towns,
    refresh,
    isLoading
  }
}

export const loadTowns = async () => {
  try {
    return await townRequests.getList({populate: 'coordinates,banner,manager'})
  } catch (e) {
    const message = (e?.message || "Error al cargar las poblaciones")
    notify.error(message)
    throw new Error(message)
  }
}



export default useTowns;
export const LOGIN = "LOGIN";
export const CHANGE_TOWN = "CHANGE_TOWN";
export const LOGOUT = "LOGOUT";

export const login = (token, user) => ({
  type: LOGIN,
  payload: {
    token: token,
    user: user
  }
});

export const logout = () => ({
  type: LOGOUT,
  payload: null
});

export const setTown = (town, towns) => ({
  type: CHANGE_TOWN,
  payload: {
    town: town,
    towns: towns
  }
});
import React from "react";
import { Navigate } from "react-router";

import Dashboard from "../../views/layouts/Dashboard";

// MainRoutes
import Towns from "../../views/pages/dashboard/towns";
import DefaultSurvey from "../../views/pages/dashboard/defaultSurvey"
import QRCode from "../../views/pages/dashboard/qrcode"
import Screensavers from "../../views/pages/dashboard/screensavers"
import Articles from "../../views/pages/dashboard/articles"
import Survey from "../../views/pages/dashboard/survey"

// SubRoutes;
import sectionsSubRoutes from "./sectionsSubroutes";


const subRoutes = state => {
  const townSection = state?.town?.name.trim() || 'Ayuntamiento'
  return [
    {
      name: "Gestión de poblaciónes",
      accessRoles: ['Admin'],
      path: "towns-management",
      showOnNav: "Administrador",
      icon: "fas fa-users-cog text-default",
      component: <Towns/>
    },
    {
      name: "Encuesta común",
      accessRoles: ['Admin'],
      path: "default-survey",
      icon: "fas fa-poll-h text-primary",
      showOnNav: "Administrador",
      component: <DefaultSurvey/>
    },
    // {
    //   name: "Videos ODS",
    //   accessRoles: ['Admin'],
    //   path: "ods",
    //   icon: "fas fa-chart-pie text-primary",
    //   showOnNav: "Administrador",
    //   // component: <Towns/>
    // },


    {
      name: "Actualidad",
      accessRoles: ['Town', 'Admin'],
      path: "news",
      icon: "fas fa-newspaper text-primary",
      showOnNav: townSection,
      component: <Articles/>
    },
    {
      name: "Encuesta",
      accessRoles: ['Town', 'Admin'],
      path: "survey",
      icon: "fas fa-poll-h text-primary",
      showOnNav: townSection,
      component: <Survey/>
    },
    {
      name: "Salvapantallas",
      accessRoles: ['Town', 'Admin'],
      path: "screensavers",
      icon: "fas fa-desktop text-primary",
      showOnNav: townSection,
      component: <Screensavers/>
    },
    {
      name: "Secciónes",
      accessRoles: ['Town', 'Admin'],
      path: "sections",
      icon: "fas fa-bars text-primary",
      showOnNav: townSection,
      subRoutes: sectionsSubRoutes
    },
    {
      name: "Código QR",
      accessRoles: ['Town', 'Admin'],
      path: "qr-code",
      icon: "fas fa-qrcode text-primary",
      showOnNav: townSection,
      component: <QRCode/>
    }
  ]
}


const redirectsRoutes = subRoutes().map(({ accessRoles, path }) => (
  {
    path: "*",
    accessRoles,
    index: true,
    component: <Navigate to={`/dashboard/${path}`} replace={true}/>
  }
))

const index = state => ({
  path: "/dashboard",
  component: <Dashboard/>,
  subRoutes: subRoutes(state).concat(redirectsRoutes)
})

export default index;
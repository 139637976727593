import { useState } from "react";
import { notify } from "@general-backoffice/core";

import {articleRequests} from "../../../api/Api"

import { submitLocalizations } from "../../utils/localizations";
import handleError from "../../utils/handleError";
import getFileId from "../../utils/getFileId";


const useArticle = () => {
  const [article, setArticle] = useState(null);
  const [isLoading, setLoading] = useState(false);


  const update = () => {
    if (!article || isLoading) return;
    setLoading(true)
    return submitArticle(article)
      .finally(() =>
        setLoading(false)
      )
  }

  const remove = async () => {
    if (!article || isLoading) return;
    setLoading(true)
    return deleteArticle(article)
      .finally(() => {
        setLoading(false)
      })
  }


  return {
    article,
    setArticle,
    update,
    remove,
    isLoading
  }
}


export const submitArticle = async article => {
  const isNew = !article?.id

  const errMsg = `Error al ${isNew ? 'crear' : 'modificar'} el artículo`
  if (!article) throw new Error(errMsg)
  try {
    const { id, banner, attached_file, localizations, ...props } = article

    // Process files
    const bannerId = await getFileId(banner)
    const attachedFileId = await getFileId(attached_file)

    const body = {
      banner: bannerId,
      attached_file: attachedFileId,
      ...props //publishing_date, town
    }

    // Create or update base entry
    const res = isNew ?
      await articleRequests.create(body)
      :
      await articleRequests.update(id, body)

    // Update or create every localization needed
    const defaultId = isNew ? res.id : id
    await submitLocalizations(localizations, defaultId, articleRequests.update, articleRequests.localize)

    notify.success(`Artículo ${isNew ? 'creado' : 'modificado'} correctamente`)
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}

export const deleteArticle = async article => {
  const errMsg = "Error al eliminar el artículo"
  if (!article?.id) throw new Error(errMsg)
  try {
    await articleRequests.delete(article.id)
    notify.success("Artículo emininado correctamente")
  } catch (e) {
    handleError(e?.message || errMsg)
  }
}


export default useArticle;
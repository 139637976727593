import React from "react";
import { TableOutput, Table, TableCard } from "@general-backoffice/core";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import moment from "moment";
import _ from "lodash"

import AddButtonChildren from "../shared/AddButtonChildren";

const defaultArticle = {
  banner: null,
  attached_file: null,
  locale: null,
  publishing_date: null,
  localizations: {},
}


const ArticlesTableCard = ({ articles, setArticleToUpdate, setArticleToDelete, isLoading }) => {
  const town = useSelector(({ sessionState }) => sessionState.town)

  const columns = [
    {
      name: "Imagen",
      formattedContent: ({ banner }) => <TableOutput.Image src={banner?.url}/>,
      isTh: true
    },
    {
      name: "Título",
      property: 'title',
      isTh: true
    },
    {
      name: "Publicado",
      formattedContent: ({ publishing_date }) =>
        (!publishing_date || moment().isSameOrAfter(publishing_date)) &&
        <TableOutput.Status status="Publicado" color="success"/>
    },
    {
      name: "Fecha de publicación",
      formattedContent: ({ publishing_date }) =>
        publishing_date && <TableOutput.TitleSubtitle title={moment(publishing_date).format('DD/MM/YYYY')}/>
    },
    {
      name: "",
      formattedContent: article =>
        <TableOutput.CustomActions
          actions={ [
            {
              icon: <i className="fas fa-edit"/>,
              onClick: () => setArticleToUpdate(_.cloneDeep(article)),
              description: "Editar artículo"
            },
            {
              icon: <i className="fas fa-trash"/>,
              onClick: () => setArticleToDelete(_.cloneDeep(article)),
              description: "Eliminar artículo"
            }
          ]}
        />
    }
  ]

  return (
    <Card className="overflow-hidden">
      <TableCard.Header
        title="Actualidad"
        buttons={[{
            children: <AddButtonChildren children={"Nueva"}/>,
            onClick: () => setArticleToUpdate({ ...defaultArticle, town: town.id })
        }]}
        isLoading={isLoading}
      />
      <Table
        columns={columns}
        rows={articles}
        onRowClick={article => setArticleToUpdate(_.cloneDeep(article))}
        isLoading={isLoading}
        noContentMessage="No hay artículos"
        rowHeight={81}
      />
    </Card>
  );
}

export default ArticlesTableCard;